import React from 'react'
import Layout from '../../components/Layout'
import AccountLayout from '../../components/Account/AccountLayout'
import SvgIcon from "../../components/SvgCurve"
import Input from "../../components/Input"
import Checkbox from "../../components/Checkbox"
import Textarea from "../../components/Textarea"

export default function Help() {
    return (
        <Layout>
            <AccountLayout>
                <div className="Help">
                    <span className="Help-title">
                        <SvgIcon className="Help-icon" icon="infoIcon" />
                        Hola José Luis, ¿En qué te podemos ayudar?
                    </span>
                    <span className="Help-subtitle">Contacta directamente</span>
                    <span className="Help-text">Si quieres hacernos algún comentario, tienes alguna duda o sugerencia, puedes utilizar el siguiente formulario. También puedes contactar con nosotros:sagradafamilia.store@laie.es</span>
                    <div className="Help-content">
                        <Input label="Nombre" />
                        <Input label="Apellidos" />
                        <Input label="Email" type="email" />
                        <Textarea text="Tu mensaje" />
                        <Checkbox className="Help-checkbox" classNameLabel="Help-label" text="He leído y acepto el tratamiento de mis datos conforme a la política de privacidad" />
                        <Checkbox className="Help-checkbox" classNameLabel="Help-label" text="Deseo recibir información comercial y ofertas por vía electrónica" />
                        <button className="Help-btn">Enviar</button>
                    </div>
                </div>
            </AccountLayout>
        </Layout>
    )
}
